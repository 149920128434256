<template>
    <asc-accordion :items="$cookieConsent.getCategories()" class="asc-cookie-preferences">
        <template v-slot:title="{ item }">
            <h4 v-if="item.id === 'analytical'">Analytics</h4>
        </template>

        <template v-slot:default="{ item }">
            <p v-if="item.id === 'analytical'" class="asc-cookie-preferences__description">
                Privacy friendly tool <a href="https://matomo.org/privacy/" target="_blank" rel="noopener">Matomo</a> helps us see what content you like most.
            </p>
            <asc-checkbox
                class="asc-cookie-preferences__checkbox"
                :value="item.id"
                :disabled="item.required"
                v-model="preferencesClone"
                @change.native="updatePreferences"
            />
        </template>
    </asc-accordion>
</template>

<script>
import AscCheckbox from '~/patterns/atoms/checkbox/checkbox.vue';
import AscAccordion from '~/patterns/molecules/accordion/accordion.vue';

export default {
    components: {
        AscAccordion,
        AscCheckbox
    },

    props: {
        preferences: {
            required: true,
            default: () => [],
            type: Array
        }
    },

    data() {
        return {
            preferencesClone: []
        };
    },

    watch: {
        preferences: {
            immediate: true,
            handler(value) {
                this.preferencesClone = value;
            }
        }
    },

    methods: {
        updatePreferences(event) {
            let prefs = [...this.preferences];
            if (event.target.checked) {
                prefs.push(event.target.value);
            } else {
                prefs = prefs.filter(pref => pref !== event.target.value);
            }
            this.$emit('update:preferences', prefs);
        }
    }
};
</script>

<style src="./cookie-preferences.less" lang="less"></style>
