export default {
    title: 'Amsterdam Smart City',
    description: 'Amsterdam Smart City is an urban open innovation platform for change makers to meet, interact and collaborate. Innovation begins where boundaries fade.',
    shareImage: 'https://amsterdamsmartcity.com/social-image.jpg',
    author: 'Amsterdam Smart City',

    twitter: {
        handle: '@adamsmartcity'
    }
};
